import React from 'react';

import Dictionary from '../../Dictionary';

export default ({ title, content }) => {
    return (
        <>
            <a name="pricingDetails"></a>
            <section className='products-pricingDetails styles_container'>
                <div className='background-productsDetails'></div>
                <div className='products-pricingDetails-title'>
                    <h1>{title}</h1>
                </div>
                <div dangerouslySetInnerHTML={{__html: content}}></div>
            </section>
        </>
    )
}